import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";

type PreviewCardProps = {
  title: string;
  subtitle: string;
  description: string;
  image: string;
  className?: string;
  onClick?: () => void;
};

const PreviewCard: React.FC<PreviewCardProps> = ({
  title,
  subtitle,
  description,
  image,
  onClick,
  className
}) => {
  const { classes } = useStyles();

  return (
    <CardActionArea onClick={onClick} className={className}>
      <Card>
        <CardMedia className={classes.media} image={image} />
        <CardContent>
          <Typography color="textSecondary">{subtitle}</Typography>
          <Typography variant="h6">{title}</Typography>
          <Typography>{description}</Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  );
};

const useStyles = makeStyles()(() => ({
  media: {
    height: 140,
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto"
  }
}));

export default PreviewCard;
