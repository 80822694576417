import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";
import PreviewCard from "../components/PreviewCard";
import { LAYOUT_PADDING } from "../constants/styles";
import theme from "../gatsby-theme-material-ui-top-layout/theme";
import PublicLayout from "../layout/PublicLayout";

const RESTAURANT = {
  name: "Steak House",
  website: "www.steakhouse.com",
  rating: 3,
  dineIn: true,
  takeOut: false,
  delivery: true,
  caption: "We are a restaurant with MATCHII",
  about:
    "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi autem iste odio quam \
  alias expedita quidem porro atque accusantium labore voluptate, ut consectetur non,            \
  rem architecto hic quaerat quasi perferendis!",
  history:
    "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi autem iste odio quam \
  alias expedita quidem porro atque accusantium labore voluptate, ut consectetur non,            \
  rem architecto hic quaerat quasi perferendis!",
  images: [
    require("../assets/sandwich.jpg"),
    require("../assets/citrus.jpg"),
    require("../assets/egg.jpg"),
    require("../assets/burrito.jpg")
  ]
};

const MORE = [
  {
    name: "Burrito Bros",
    type: "RESTAURANT",
    caption: "We are burrito bros!",
    image: require("../assets/burrito.jpg")
  },
  {
    name: "Dimsum Door",
    type: "RESTAURANT",
    caption: "We are Dimsum dooor!",
    image: require("../assets/burrito.jpg")
  },
  {
    name: "Curry Court",
    type: "RESTAURANT",
    caption: "We are Curry court!",
    image: require("../assets/burrito.jpg")
  },
  {
    name: "Pizza Palace",
    type: "RESTAURANT",
    caption: "We are Pizza palace!",
    image: require("../assets/burrito.jpg")
  }
];

const DiscoverRestaurant = () => {
  const { classes } = useStyles();
  const restaurant = RESTAURANT;
  const moreRestaurants = MORE;

  return (
    <PublicLayout seoTitle="Discover Restaurants">
      <Grid container component="main">
        <Grid item sm={12} md={8} className={classes.main}>
          <div>
            <Typography variant="h4" gutterBottom>
              {restaurant.name}
              {/*<Rating*/}
              {/*  name="read-only"*/}
              {/*  value={restaurant.rating}*/}
              {/*  readOnly*/}
              {/*  className={classes.rating}*/}
              {/*/>*/}
            </Typography>

            <Box mb={2} mt={1}>
              <Button variant="outlined" className={classes.button}>
                Website
              </Button>
              <Button variant="outlined" className={classes.button}>
                Directions
              </Button>
              <Button variant="outlined" className={classes.button}>
                Phone
              </Button>

              <Typography>{restaurant.caption}</Typography>
            </Box>

            <Box mb={4}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={5}
              >
                <OptionSection text="Delivery" supported={restaurant.delivery} />
                <OptionSection text="Dine In" supported={restaurant.dineIn} />
                <OptionSection text="Take Out" supported={restaurant.takeOut} />
              </Grid>
            </Box>

            <Box mb={4}>
              <Typography variant="h5">About</Typography>
              <Typography>{restaurant.about}</Typography>
            </Box>

            <Box mb={4}>
              <Typography variant="h5">History</Typography>
              <Typography>{restaurant.history}</Typography>
            </Box>

            <Box mb={4}>
              <Typography variant="h5">We are located here</Typography>
              <Typography>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi autem iste odio
                quam alias expedita quidem porro atque accusantium labore voluptate, ut consectetur
                non, rem architecto hic quaerat quasi perferendis!
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography variant="h5" gutterBottom>
                Get to know us more
              </Typography>
              <Grid container spacing={1}>
                {restaurant.images.map((src, i) => (
                  <Grid item key={i}>
                    <img className={classes.media} src={src} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </div>
        </Grid>
        <Grid item md={4} className={classes.moreRestaurant}>
          <Typography gutterBottom variant="h5" align="center">
            More Restaurants
          </Typography>
          {moreRestaurants.map((restaurant, i) => (
            <PreviewCard
              key={i}
              className={i ? classes.card : ""}
              title={restaurant.name}
              subtitle={restaurant.type}
              description={restaurant.caption}
              image={restaurant.image}
            />
          ))}
        </Grid>
      </Grid>
    </PublicLayout>
  );
};

const OptionSection = ({ supported, text }: any) => {
  return (
    <Grid item>
      <Box display="flex" alignItems="center">
        {supported ? (
          <CheckIcon htmlColor={theme.palette.success.main} />
        ) : (
          <ClearIcon color="error" />
        )}
        {text}
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles()((theme) => ({
  main: {
    padding: theme.spacing(LAYOUT_PADDING)
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  card: {
    marginTop: theme.spacing(LAYOUT_PADDING)
  },
  moreRestaurant: {
    padding: theme.spacing(LAYOUT_PADDING),
    borderLeft: "2px solid #EDEDED",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  media: {
    height: 140,
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto"
  }
}));

export default DiscoverRestaurant;
